import {
  constructPromoUrl,
  isCampaignBanner,
  isCollectionBanner,
  isEditorialBanner
} from '@helpers/campaigns'

import { CAMPAIGN_CTA, COLLECTION_CTA, PATH_SUFFIX } from './constants'

export const getHeroTileCta = (promoItem, t) => {
  if (isCampaignBanner(promoItem))
    return t('m_web_hero_tile_CTA_view_more', { fallback: CAMPAIGN_CTA })

  if (isCollectionBanner(promoItem))
    return t('m_web_hero_tile_CTA_discover', { fallback: COLLECTION_CTA })

  return promoItem?.cta
}

export const getHeroTileTitle = (promoItem) => {
  if (isCampaignBanner(promoItem)) return promoItem?.campaign?.name

  if (isCollectionBanner(promoItem)) return promoItem?.collection?.name

  return promoItem?.title
}

export const getHeroTileUrl = (promoItem) => {
  if (isEditorialBanner(promoItem))
    return promoItem?.url + `?source=${PATH_SUFFIX}`

  return constructPromoUrl(promoItem, PATH_SUFFIX)
}
