import { CCGS } from '@constants/closed_consumer_groups'
import {
  REDEMPTION_CLASSES,
  REDEMPTION_TYPES,
  REDEMPTION_STEP_ADDITIONAL_CONTENT,
  OFFER_CONTENT_TYPES,
  OFFER_CONTENT_TYPES_LOWER
} from '@constants/offers'
import { getNextRoute, getUrlQueryObject } from '@helpers/routes'

export const isInstore = (redemptionClass) =>
  redemptionClass === REDEMPTION_CLASSES.INSTORE
export const isOnline = (redemptionClass) =>
  redemptionClass === REDEMPTION_CLASSES.ONLINE
export const isSbid = (redemptionType) =>
  redemptionType === REDEMPTION_TYPES.SBID

export const isInApp = ({
  redemptionClass,
  redemptionType,
  baseRedemptionType
}) => {
  return (
    isInstore(redemptionClass) &&
    isSbid(redemptionType) &&
    hasAppLinks(baseRedemptionType)
  )
}

export const hasAppLinks = (baseRedemptionType) =>
  !!(
    baseRedemptionType?.androidStore &&
    baseRedemptionType.iosStore &&
    baseRedemptionType.installInstructions
  )

export const isAggregatedOffer = (offer) =>
  offer.baseRedemptionType?.redemptionStep?.uxType ===
  REDEMPTION_STEP_ADDITIONAL_CONTENT

export const isNativeStudentDiscount = (baseRedemptionType) => {
  return (
    baseRedemptionType.contentType ===
    OFFER_CONTENT_TYPES.NATIVE_STUDENT_DISCOUNT
  )
}

export const isCompetitorStudentDiscount = (baseRedemptionType) => {
  return (
    baseRedemptionType.contentType ===
    OFFER_CONTENT_TYPES.COMPETITOR_STUDENT_DISCOUNT
  )
}

export const isVoucher = (baseRedemptionType) => {
  return baseRedemptionType.contentType === OFFER_CONTENT_TYPES.VOUCHER
}

export const isFreebie = (baseRedemptionType) => {
  return baseRedemptionType.contentType === OFFER_CONTENT_TYPES.FREEBIE
}

export const isSale = (baseRedemptionType) => {
  return baseRedemptionType.contentType === OFFER_CONTENT_TYPES.SALE
}

export const isCompetition = (baseRedemptionType) => {
  return baseRedemptionType.contentType === OFFER_CONTENT_TYPES.COMPETITION
}

export const isStudentDiscount = (offer) => {
  return (
    offer?.contentType === OFFER_CONTENT_TYPES_LOWER.NATIVE_STUDENT_DISCOUNT ||
    offer?.contentType ===
      OFFER_CONTENT_TYPES_LOWER.COMPETITOR_STUDENT_DISCOUNT ||
    offer?.contentType === OFFER_CONTENT_TYPES_LOWER.COMPETITION
  )
}

export const isAggregatedDiscount = (offer) => {
  return (
    offer?.contentType === OFFER_CONTENT_TYPES_LOWER.VOUCHER ||
    offer?.contentType === OFFER_CONTENT_TYPES_LOWER.FREEBIE ||
    offer?.contentType === OFFER_CONTENT_TYPES_LOWER.SALE
  )
}

export const isGradOfferOnSBPage = (offer, isGradBeansPage) =>
  isGraduateOffer(offer) && !isGradBeansPage

export const isOfferSelected = (offer) => {
  const selectedOffer = getUrlQueryObject().offer
  return offer.node?.slug === selectedOffer
}

export const sortOffersSelectedFirst = (offers) => {
  if (!Array.isArray(offers)) return null

  const orderedOffers = [...offers]
  return orderedOffers?.sort?.((currentOffer, nextOffer) => {
    if (isOfferSelected(currentOffer)) return -1

    if (isOfferSelected(nextOffer)) return 1

    return 0
  })
}

export const isGraduateOffer = (offer) => {
  return (
    offer?.closedConsumerGroup === CCGS.GRADUATE ||
    offer?.consumerGroups === CCGS.GRADUATE
  )
}

export const getOfferUrl = (offer, suffix, isGradBeansPage) => {
  const country = offer?.country?.slug.toLowerCase()
  const brand = offer?.brand?.slug
  const offerSlug = offer?.slug

  if (isGradBeansPage) {
    return (
      getNextRoute('grad.brand', { country, brand, offer: offerSlug }) +
      getUrlParams(suffix, offerSlug)
    )
  }

  return (
    getNextRoute('brand.show', { country, brand, offer: offerSlug }) +
    getUrlParams(suffix, offerSlug)
  )
}

export const getUrlParams = (suffix, offerSlug) =>
  suffix ? `${suffix}&offer=${offerSlug}` : `?offer=${offerSlug}`
