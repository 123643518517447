'use client'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import ChevronThinIcon from '@thebeansgroup/ui/icons/chevron_thin'
import Typography from '@thebeansgroup/ui/typography'

import { useI18n } from '@hooks/use_i18n'

import { VIEW_MORE_FALLBACK } from './constants'

const ContentRail = ({
  children,
  hasMarginBottom,
  subtitle,
  title,
  viewMoreHref,
  className
}) => {
  const { t } = useI18n()

  const renderViewMoreLink = () => {
    if (!viewMoreHref) return null

    return (
      <a
        data-testid='content-rail-view-more-link'
        className='flex flex-shrink-0 items-center text-base text-violet-500 hover:text-lavender-500 stroke-violet-500 hover:stroke-lavender-500'
        href={viewMoreHref}
      >
        <span className='hidden lg:block'>
          {t('js.content_strip.common.view_more', {
            fallback: VIEW_MORE_FALLBACK
          })}
        </span>
        <ChevronThinIcon className='-rotate-90 ml-2' />
      </a>
    )
  }

  const renderSubtitle = () => {
    if (!subtitle) return null

    return (
      <Typography
        data-testid='content-rail-subtitle'
        variant='subhead'
        component='h3'
        className='!text-grey-400 '
      >
        {subtitle}
      </Typography>
    )
  }

  const renderHeader = () => {
    return (
      <div className='flex justify-between items-center mb-4'>
        <div className='pr-4 max-w-[520px]'>
          <Typography
            data-testid='content-rail-title'
            variant='title3'
            component='h2'
            className='!text-grey-500'
            fontWeight='semibold'
          >
            {title}
          </Typography>
          {renderSubtitle()}
        </div>
        {renderViewMoreLink()}
      </div>
    )
  }

  return (
    <section
      data-testid='content_rail'
      className={clsx(className, hasMarginBottom && 'mb-5 lg:mb-9')}
    >
      {renderHeader()}
      <div>{children}</div>
    </section>
  )
}

ContentRail.propTypes = {
  children: PropTypes.node.isRequired,
  hasMarginBottom: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  viewMoreHref: PropTypes.string,
  className: PropTypes.string
}

ContentRail.defaultProps = {
  hasMarginBottom: false,
  subtitle: null,
  viewMoreHref: null,
  className: ''
}

export default ContentRail
