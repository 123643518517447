import { getCountryOptions } from '@helpers/countries'

export const isKevelPromoBoxCountry = (country) => {
  const { hasKevelPromoBoxes } = getCountryOptions(country)

  return !!hasKevelPromoBoxes
}

export const shouldRenderHeroBoxes = (country) =>
  isKevelPromoBoxCountry(country)
