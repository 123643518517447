import { PLACEMENT_TYPE_PAID } from '@constants/impression_tracking'

import { IMPRESSION_GROUP_ID, IMPRESSION_GROUP_TYPE } from './constants'

export const shouldRenderMostPopularRail = (offers) => {
  return offers?.edges?.length > 0
}

export const getImpressionContent = (groupPosition, isAdvert) => {
  const impressionObject = {
    groupType: IMPRESSION_GROUP_TYPE,
    groupId: IMPRESSION_GROUP_ID,
    groupPosition
  }

  if (isAdvert) {
    impressionObject.placementType = PLACEMENT_TYPE_PAID
  }

  return impressionObject
}
