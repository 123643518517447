import clsx from 'clsx'
import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'

import SafeImage from '@components/safe_image'
import { PROMO_ITEM_PROP_TYPES } from '@constants/campaigns'
import { getPromoStyles } from '@helpers/campaigns'
import { useI18n } from '@hooks/use_i18n'

import { getHeroTileCta, getHeroTileTitle, getHeroTileUrl } from './helpers'

const HeroTile = ({ promoItem, className }) => {
  const { t } = useI18n()

  const promoStyles = getPromoStyles(promoItem)

  const renderImage = () => {
    return (
      <div className='relative w-full h-[110px] md:h-[160px] lg:h-[190px]'>
        <SafeImage
          src={promoStyles?.image?.mobile?.jpeg}
          fill
          style={{ objectFit: 'cover' }}
          className='lg:hidden'
          alt=''
        />
        <SafeImage
          src={promoStyles?.image?.desktop?.jpeg}
          fill
          style={{ objectFit: 'cover' }}
          className='hidden lg:block'
          alt=''
        />
      </div>
    )
  }

  const renderTitle = () => {
    const title = getHeroTileTitle(promoItem, t)
    return (
      <>
        <Typography
          variant='footnote'
          component='h3'
          fontWeight='semibold'
          className='lg:hidden'
        >
          {title}
        </Typography>
        <Typography
          variant='headline'
          component='h3'
          fontWeight='semibold'
          className='hidden lg:block'
        >
          {title}
        </Typography>
      </>
    )
  }

  const renderCta = () => {
    return (
      <Typography
        variant='subhead'
        component='span'
        className='hidden lg:block text-violet-400'
      >
        {getHeroTileCta(promoItem, t)}
      </Typography>
    )
  }

  return (
    <a
      href={getHeroTileUrl(promoItem)}
      className={clsx('flex flex-col flex-wrap gap-3 h-full', className)}
      data-testid='hero-tile'
    >
      {renderImage()}
      <div className='flex justify-between flex-col p-3 flex-1 gap-[10px] break-words w-full xl:gap-0 xl:pt-4 xl:px-4 xl:pb-3'>
        {renderTitle()}
        {renderCta()}
      </div>
    </a>
  )
}

HeroTile.defaultProps = {
  promoItem: {},
  className: ''
}

HeroTile.propTypes = {
  promoItem: PROMO_ITEM_PROP_TYPES.isRequired,
  className: PropTypes.string
}

export default HeroTile
