'use client'

import PropTypes from 'prop-types'

const MobileSwipeableRailItem = ({ children }) => {
  return (
    <div
      data-testid='mobile-swipeable-rail-item'
      className='focus-visible:outline focus-visible:outline-2 focus-visible:outline-liquorice-500'
    >
      {children}
    </div>
  )
}

MobileSwipeableRailItem.propTypes = {
  children: PropTypes.node.isRequired
}

export default MobileSwipeableRailItem
