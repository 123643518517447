import Metadata from '@components/metadata'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

import { getTitle, getDescription, getCanonical, getLdData } from './helpers'

const HomePageMetadata = () => {
  const { t } = useI18n()
  const country = useCountryCode()

  return (
    <Metadata
      title={getTitle(t)}
      description={getDescription(t)}
      canonicalUrl={getCanonical(country)}
      ldData={getLdData()}
    />
  )
}

export default HomePageMetadata
