/* istanbul ignore file */
import { gql } from '@apollo/client'

import { OFFER_TILE_NEW_FRAGMENT } from '@components/offer_tile/constants'

export const OFFER_RAIL_FRAGMENT = gql`
  fragment OfferRailOffers on OffersConnection {
    edges {
      node {
        ...OfferTileNewOffer
      }
    }
  }
  ${OFFER_TILE_NEW_FRAGMENT}
`
