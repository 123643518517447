import clsx from 'clsx'
import PropTypes from 'prop-types'

import Placeholder from '@components/placeholder'

const ContentRailPlaceholder = ({ children, hasMarginBottom, hasSubtitle }) => {
  return (
    <div
      className={clsx(hasMarginBottom && 'mb-5 lg:mb-9')}
      role='presentation'
      aria-hidden='true'
      data-testid='content_rail_placeholder'
    >
      <div className='mb-4'>
        <Placeholder
          width='300px'
          height={hasSubtitle ? '50px' : '30px'}
          rounded
        />
      </div>
      {children}
    </div>
  )
}

ContentRailPlaceholder.propTypes = {
  children: PropTypes.node.isRequired,
  hasMarginBottom: PropTypes.bool,
  hasSubtitle: PropTypes.bool
}

ContentRailPlaceholder.defaultProps = {
  hasMarginBottom: false,
  hasSubtitle: false
}

export default ContentRailPlaceholder
