'use client'

import PropTypes from 'prop-types'

import OfferRail from '@components/offer_rail'
import { getAdvertOffer, getCombinedAdvertAndOffers } from '@helpers/kevel'
import { useI18n } from '@hooks/use_i18n'
import { useUserData } from '@hooks/use_user_data'

import {
  NEW_TODAY_AD_POSITION,
  NEW_TODAY_DATA_NAME,
  NEW_TODAY_RAIL_SUBTITLE_FALLBACK,
  NEW_TODAY_RAIL_TITLE_FALLBACK,
  NEW_TODAY_SUFFIX
} from './constants'
import { getImpressionContent } from './helpers'

const NewTodayRail = ({ offers, groupPosition }) => {
  const { t } = useI18n()
  const { data, loading } = useUserData()

  const advertOffer = getAdvertOffer(data, NEW_TODAY_DATA_NAME)
  const combinedOffers = getCombinedAdvertAndOffers(
    advertOffer,
    offers,
    NEW_TODAY_AD_POSITION
  )

  return (
    <OfferRail
      hasMarginBottom
      impressionContent={getImpressionContent(groupPosition)}
      loading={loading}
      offers={combinedOffers?.edges}
      pathSuffix={NEW_TODAY_SUFFIX}
      title={t('d_new_today_title', {
        fallback: NEW_TODAY_RAIL_TITLE_FALLBACK
      })}
      subtitle={t('m_new_today_subtitle', {
        fallback: NEW_TODAY_RAIL_SUBTITLE_FALLBACK
      })}
    />
  )
}

NewTodayRail.defaultProps = {
  offers: {}
}

NewTodayRail.propTypes = {
  groupPosition: PropTypes.number.isRequired,
  offers: PropTypes.object
}

export default NewTodayRail
