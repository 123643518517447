import { PLACEMENT_TYPE_PAID } from '@constants/impression_tracking'

export const getImpressionContent = (impressionContent, isAdvert, index) => {
  if (!impressionContent) return null

  const impressionObject = {
    ...impressionContent,
    contentPosition: index
  }

  if (isAdvert) {
    impressionObject.placementType = PLACEMENT_TYPE_PAID
  }

  return impressionObject
}
