import { gql } from '@apollo/client'
import dynamic from 'next/dynamic'
import PropTypes from 'prop-types'

import Container from '@thebeansgroup/ui_grids/container'

import ComponentErrorBoundary from '@components/component_error_boundary'
import CuratedCollectionRail from '@components/curated_collection_rail'
import HomePageHero from '@components/home_page_hero'
import HomePageMetadata from '@components/home_page_metadata'
import MostPopularRailWithAd from '@components/most_popular_rail_with_ad'
import NewTodayRail from '@components/new_today_rail'
import Page from '@components/page'
import { PAGE_VIEWER_HEADER_FRAGMENT } from '@components/page/constants'
import { CCGS } from '@constants/closed_consumer_groups'
import { HOME_PAGE_LABEL } from '@constants/home_page'
import { KEVEL_OFFER_TILE_FRAGMENT } from '@constants/kevel'

const HomePageReviews = dynamic(() => import('@components/home_page_reviews'))

const KevelFeaturedOffers = dynamic(
  () => import('@components/kevel_featured_offers')
)

const RecommendedOffersRail = dynamic(
  () => import('@components/recommended_offers_rail')
)

const ValuePropRegister = dynamic(
  () => import('@components/value_prop_register')
)
const ValuePropWhy = dynamic(() => import('@components/value_prop_why'))

const HOME_USER_QUERY = gql`
  query WebHomeUserDataQuery($countryCode: String!) {
    accountsViewer {
      id
      ...PageViewerHeader
      kevelTrendingNowAd: kevelAdverts(
        countrySlug: $countryCode
        platform: WEB
        placementType: TRENDING_NOW
        count: 1
      ) {
        ...KevelOfferTileViewer
      }
      kevelNewTodayAd: kevelAdverts(
        count: 1
        countrySlug: $countryCode
        placementType: NEW_TODAY
        platform: WEB
      ) {
        ...KevelOfferTileViewer
      }
    }
  }
  ${PAGE_VIEWER_HEADER_FRAGMENT}
  ${KEVEL_OFFER_TILE_FRAGMENT}
`

const HomePage = ({
  promoBlocks,
  popularOffers,
  newTodayOffers,
  curatedCollections,
  heroPromoBlocks,
  countryCode
}) => {
  const renderCuratedCollections = () => {
    return (
      <>
        <CuratedCollectionRail
          curatedCollections={curatedCollections}
          curatedCollectionOffset={0}
          groupPosition={8}
        />
        <CuratedCollectionRail
          curatedCollections={curatedCollections}
          curatedCollectionOffset={1}
          groupPosition={9}
        />
        <CuratedCollectionRail
          curatedCollections={curatedCollections}
          curatedCollectionOffset={2}
          groupPosition={10}
        />
      </>
    )
  }

  const renderFeaturedOffers = () => {
    return <KevelFeaturedOffers groupPosition={4} />
  }

  const renderTrendingOffers = () => {
    return (
      <MostPopularRailWithAd
        offers={popularOffers}
        groupPosition={3}
        isFullWidth
      />
    )
  }

  const renderRecommendedOffersRail = () => {
    return (
      <ComponentErrorBoundary>
        <RecommendedOffersRail groupPosition={2} />
      </ComponentErrorBoundary>
    )
  }

  const renderNewTodayRail = () => {
    return (
      <ComponentErrorBoundary>
        <NewTodayRail
          offers={newTodayOffers}
          groupPosition={6}
        />
      </ComponentErrorBoundary>
    )
  }

  return (
    <Page
      displayAppDownloadBanner
      query={HOME_USER_QUERY}
      pageLabel={HOME_PAGE_LABEL}
      fullWidth
      queryParams={{
        countryCodes: [countryCode],
        countryCode,
        closedConsumerGroup: CCGS.STUDENT
      }}
      promoBlocks={promoBlocks}
    >
      <HomePageMetadata />
      <HomePageHero
        promoBlocks={heroPromoBlocks}
        countryCode={countryCode}
        groupPosition={1}
      />
      <Container full>
        <div className='p-0 md:px-3 lg:px-5'>
          {renderRecommendedOffersRail()}
          {renderTrendingOffers()}
          {renderFeaturedOffers()}
          {renderNewTodayRail()}
          <ValuePropRegister />
          {renderCuratedCollections()}
          <ValuePropWhy />
          <HomePageReviews />
        </div>
      </Container>
    </Page>
  )
}

HomePage.propTypes = {
  promoBlocks: PropTypes.array,
  popularOffers: PropTypes.object,
  newTodayOffers: PropTypes.object,
  curatedCollections: PropTypes.array,
  heroPromoBlocks: PropTypes.array,
  countryCode: PropTypes.string.isRequired
}

HomePage.defaultProps = {
  promoBlocks: null,
  trendingOffers: null,
  popularOffers: null,
  newTodayOffers: null,
  curatedCollections: [],
  heroPromoBlocks: []
}

export default HomePage
