import PropTypes from 'prop-types'

import ContentRailPlaceholder from '@components/content_rail/placeholder'
import MobileSwipeableRailPlaceholder from '@components/mobile_swipeable_rail/placeholder'

const OfferRailPlaceholder = ({
  hasMarginBottom,
  hasSubtitle,
  numberOfItems
}) => {
  return (
    <ContentRailPlaceholder
      hasMarginBottom={hasMarginBottom}
      hasSubtitle={hasSubtitle}
    >
      <MobileSwipeableRailPlaceholder numberOfItems={numberOfItems} />
    </ContentRailPlaceholder>
  )
}

OfferRailPlaceholder.propTypes = {
  hasMarginBottom: PropTypes.bool,
  hasSubtitle: PropTypes.bool,
  numberOfItems: PropTypes.number.isRequired
}

OfferRailPlaceholder.defaultProps = {
  hasMarginBottom: false,
  hasSubtitle: false
}

export default OfferRailPlaceholder
