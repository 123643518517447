import clsx from 'clsx'
import PropTypes from 'prop-types'

import ContentRail from '@components/content_rail'
import HeroTile from '@components/hero_tile'
import OfferTileMini from '@components/offer_tile_mini'
import { PROMO_ITEM_PROP_TYPES } from '@constants/campaigns'
import { hasValidPromo } from '@helpers/campaigns'
import { shouldRenderHeroBoxes } from '@helpers/homepage'
import { useI18n } from '@hooks/use_i18n'

import { PATH_SUFFIX } from './constants'
import {
  getHeroBoxesTitle,
  getImpressionContent,
  shouldCentreTiles
} from './helpers'
import HeroBoxesPlaceholder from './placeholder'

const HeroBoxes = ({
  promoItems,
  offers,
  loading,
  countryCode,
  groupPosition
}) => {
  const { t } = useI18n()
  const renderOffer = (offer, index) => {
    return (
      <OfferTileMini
        key={index}
        offer={offer}
        pathSuffix={PATH_SUFFIX}
        mini
        impressionContent={getImpressionContent(offer, groupPosition, index)}
        className='w-[230px] snap-start xl:w-[250px]'
      />
    )
  }

  const renderOffersBox = () => {
    if (!shouldRenderHeroBoxes(countryCode)) return null

    if (loading) return <HeroBoxesPlaceholder />

    if (!offers?.length) return null

    return (
      <ContentRail
        title={getHeroBoxesTitle(offers, t)}
        className='shadow-2 rounded-2 bg-white-300 z-6 overflow-hidden w-full p-4 xl:max-w-[580px]'
      >
        <div className='overflow-auto snap-x snap-mandatory scroll-smooth grid-flow-col no-scrollbar::-webkit-scrollbar no-scrollbar xl:overflow-visible'>
          <div className='grid grid-rows-3 grid-cols-2 gap-y-3 gap-x-4 justify-start lg:gap-y-2 lg:gap-x-5 min-w-[476px]'>
            {offers?.map(renderOffer)}
          </div>
        </div>
      </ContentRail>
    )
  }

  const renderTile = (item, index) => {
    if (!hasValidPromo(item.node)) return null

    return (
      <HeroTile
        key={index}
        promoItem={item.node}
        className='shadow-2 rounded-1 bg-white-300 z-6 overflow-hidden flex-1 max-w-[130px] min-w-[90px] md:max-w-[335px] lg:min-w-[145px] xl:max-w-[270px]'
      />
    )
  }

  const renderHeroTileBoxes = () => {
    return (
      <div
        className={clsx(
          'flex gap-3 flex-[4] flex-wrap justify-center xl:gap-4',
          shouldCentreTiles(offers, countryCode)
            ? 'xl:justify-center'
            : 'xl:justify-start'
        )}
      >
        {promoItems?.map(renderTile)}
      </div>
    )
  }

  return (
    <div className='flex gap-4 w-full flex-wrap justify-center z-5'>
      {renderOffersBox()}
      {renderHeroTileBoxes()}
    </div>
  )
}

HeroBoxes.propTypes = {
  promoItems: PropTypes.arrayOf(PROMO_ITEM_PROP_TYPES).isRequired,
  offers: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  countryCode: PropTypes.string.isRequired,
  groupPosition: PropTypes.number.isRequired
}

export default HeroBoxes
