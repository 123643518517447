/* istanbul ignore file */
import { gql } from '@apollo/client'

import { OFFER_RAIL_FRAGMENT } from '@components/offer_rail/constants'

export const SUFFIX_PARTIAL_SOURCE = '?source='
export const SUFFIX_SOURCE_TYPE = '_curated_strip'

export const CURATED_COLLECTION_RAIL_COLLECTIONS_FRAGMENT = gql`
  fragment CuratedCollectionRailCollections on DiscoveryCollectionsConnection {
    edges {
      node {
        name
        description
        countrySlug
        slug
        impressionContent {
          id
          type
          version
        }
        offers: collectionOffers(first: 20) {
          ...OfferRailOffers
        }
      }
    }
  }
  ${OFFER_RAIL_FRAGMENT}
`
