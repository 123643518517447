import Placeholder from '@components/placeholder'

const OfferTileMiniPlaceholder = () => {
  const renderMobilePlaceholder = () => {
    return (
      <div className='block w-[250px] xl:hidden'>
        <Placeholder
          width='100%'
          height='75px'
          rounded
        />
      </div>
    )
  }

  const renderDesktopPlaceholder = () => {
    return (
      <div className='hidden w-[250px] p-3 xl:block'>
        <Placeholder
          width='100%'
          height='74.5px'
          rounded
        />
      </div>
    )
  }

  return (
    <>
      {renderMobilePlaceholder()}
      {renderDesktopPlaceholder()}
    </>
  )
}

export default OfferTileMiniPlaceholder
