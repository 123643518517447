import { gql } from '@apollo/client'

import { OFFER_TILE_NEW_FRAGMENT } from '@components/offer_tile/constants'

export const NEW_TODAY_RAIL_FRAGMENT = gql`
  fragment NewTodayRailOffers on OffersConnection {
    edges {
      node {
        uid
        ...OfferTileNewOffer
      }
    }
  }
  ${OFFER_TILE_NEW_FRAGMENT}
`

export const IMPRESSION_GROUP_TYPE = 'promotional_content'
export const IMPRESSION_GROUP_ID = 'new_today_discounts'

export const NEW_TODAY_RAIL_TITLE_FALLBACK = 'New Today'
export const NEW_TODAY_RAIL_SUBTITLE_FALLBACK =
  'Find the latest and greatest drops every day'

export const NEW_TODAY_SUFFIX = '?source=new_today'

export const NEW_TODAY_AD_POSITION = 1
export const NEW_TODAY_DATA_NAME = 'kevelNewTodayAd'
