'use client'

import PropTypes from 'prop-types'

import MostPopularRail from '@components/most_popular_rail'
import OfferRailPlaceholder from '@components/offer_rail/placeholder'
import { getAdvertOffer, getCombinedAdvertAndOffers } from '@helpers/kevel'
import { useUserData } from '@hooks/use_user_data'

import {
  MOST_POPULAR_HOME_PAGE_COUNT,
  TRENDING_NOW_AD_POSITION,
  TRENDING_NOW_DATA_NAME
} from './constants'

const MostPopularRailWithAd = ({ groupPosition, offers, isFullWidth }) => {
  const { data, loading } = useUserData()

  if (loading) {
    return (
      <OfferRailPlaceholder
        hasMarginBottom
        hasSubtitle
        numberOfItems={MOST_POPULAR_HOME_PAGE_COUNT}
      />
    )
  }

  const advertOffer = getAdvertOffer(data, TRENDING_NOW_DATA_NAME)
  const combinedOffers = getCombinedAdvertAndOffers(
    advertOffer,
    offers,
    TRENDING_NOW_AD_POSITION
  )

  return (
    <div data-testid='most-popular-rail'>
      <MostPopularRail
        groupPosition={groupPosition}
        offers={combinedOffers}
        isFullWidth={isFullWidth}
      />
    </div>
  )
}

MostPopularRailWithAd.propTypes = {
  groupPosition: PropTypes.number.isRequired,
  offers: PropTypes.object,
  isFullWidth: PropTypes.bool
}

MostPopularRailWithAd.defaultProps = {
  offers: null,
  shouldRenderTitle: true,
  isFullWidth: false
}

export default MostPopularRailWithAd
