export const getBackgroundHeight = (offers, promoItems, loading) => {
  if (loading || showHeroBoxes(offers, promoItems)) {
    return 'h-[450px]'
  }

  return 'h-full'
}

export const showHeroBoxes = (offers, promoItems) => {
  return !!offers?.length || !!promoItems?.length
}
