import { useEffect, useRef, useState } from 'react'

import { ONE_SECOND, FIFTY_PERCENT_VIEWABLE } from './constants'
import { shouldObserve } from './helpers'

const useImpressionView = (
  ref,
  handleImpressionView,
  impressionId,
  isSnowplowSetup,
  kevelImpressionData
) => {
  const [hasBeenViewed, setHasBeenViewed] = useState(false)
  const observer = useRef(null)
  let timer = false

  const cleanObserver = () => {
    if (observer.current) {
      observer.current.disconnect()
    }
  }

  const handleImpressionViewTimeout = () => {
    handleImpressionView(impressionId, kevelImpressionData)
    setHasBeenViewed(true)
    cleanObserver()
  }

  const handleIntersectionChange = ([entry]) => {
    const isElementIntersecting = entry.isIntersecting
    if (!isElementIntersecting) {
      clearTimeout(timer)
      return
    }
    timer = setTimeout(handleImpressionViewTimeout, ONE_SECOND)
  }

  useEffect(() => {
    if (!shouldObserve(ref, hasBeenViewed, isSnowplowSetup)) {
      return
    }

    cleanObserver()
    const ob = (observer.current = new window.IntersectionObserver(
      handleIntersectionChange,
      { threshold: FIFTY_PERCENT_VIEWABLE }
    ))
    ob.observe(ref.current)
    return () => {
      cleanObserver()
    }
  }, [ref, isSnowplowSetup]) // eslint-disable-line react-hooks/exhaustive-deps
}

export default useImpressionView
