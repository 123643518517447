'use client'

import clsx from 'clsx'
import PropTypes from 'prop-types'

import Typography from '@thebeansgroup/ui/typography'
import { Container } from '@thebeansgroup/ui_grids'

import HeroBoxes from '@components/hero_boxes'
import SafeImage from '@components/safe_image'
import { PROMO_BLOCKS_PROP_TYPES } from '@constants/campaigns'
import { withAssetPrefix } from '@helpers/assets'
import { getPromoItemsByBlockType } from '@helpers/campaigns'
import { formatKevelOfferData } from '@helpers/kevel'
import { useI18n } from '@hooks/use_i18n'
import { useQuery } from '@hooks/use_query'

import {
  HERO_LEFT_IMAGE_PATH,
  HERO_RIGHT_IMAGE_PATH,
  HERO_DESKTOP_IMAGE_LEFT_WIDTH,
  HERO_DESKTOP_IMAGE_RIGHT_WIDTH,
  HERO_DESKTOP_IMAGE_HEIGHT,
  HERO_TITLE_FALLBACK,
  HERO_SUBTITLE_FALLBACK,
  HOMEPAGE_HERO_TILE_TYPE,
  HOME_PAGE_HERO_QUERY
} from './constants'
import { getBackgroundHeight, showHeroBoxes } from './helpers'

const HomePageHero = ({ promoBlocks, countryCode, groupPosition }) => {
  const { t } = useI18n()
  const { data, loading } = useQuery(HOME_PAGE_HERO_QUERY, {
    variables: { countryCode }
  })

  const offers = formatKevelOfferData(data)
  const promoItems = getPromoItemsByBlockType(
    promoBlocks,
    HOMEPAGE_HERO_TILE_TYPE
  )

  const showHero = showHeroBoxes(offers, promoItems)

  const renderTitle = () => {
    const title = t('d_hero_value_prop_title', {
      fallback: HERO_TITLE_FALLBACK
    })

    return (
      <div className='text-left md:text-center'>
        <Typography
          variant='largeTitle'
          component='h1'
          className='text-neon-500 lg:hidden'
          fontWeight='bold'
        >
          {title}
        </Typography>
        <Typography
          variant='uber2'
          component='h1'
          className='text-neon-500 hidden lg:block'
          fontWeight='bold'
        >
          {title}
        </Typography>
      </div>
    )
  }

  const renderSubtitle = () => {
    const subtitle = t('d_hero_value_prop_subtitle', {
      fallback: HERO_SUBTITLE_FALLBACK
    })

    return (
      <div className='max-w-[750px] text-left md:text-center '>
        <Typography
          variant='callout'
          className='text-white-300 lg:hidden'
        >
          {subtitle}
        </Typography>
        <Typography className='text-white-300 hidden lg:block'>
          {subtitle}
        </Typography>
      </div>
    )
  }

  const renderHeroBoxes = () => {
    if (!showHero) return null

    return (
      <HeroBoxes
        promoItems={promoItems}
        offers={offers}
        loading={loading}
        countryCode={countryCode}
        groupPosition={groupPosition}
      />
    )
  }

  const renderBackground = () => {
    const backgroundHeight = getBackgroundHeight(offers, promoItems, loading)

    return (
      <div
        className={clsx(
          'absolute top-0 gradient-violet-fall bg-no-repeat w-full',
          `${backgroundHeight}`
        )}
      >
        <div className='hidden absolute top-0 left-0 z-1 xl:block'>
          <SafeImage
            alt=''
            src={withAssetPrefix(HERO_LEFT_IMAGE_PATH)}
            width={HERO_DESKTOP_IMAGE_LEFT_WIDTH}
            height={HERO_DESKTOP_IMAGE_HEIGHT}
          />
        </div>
        <div className='hidden absolute bottom-0 right-0 z-1 xl:block'>
          <SafeImage
            alt=''
            src={withAssetPrefix(HERO_RIGHT_IMAGE_PATH)}
            width={HERO_DESKTOP_IMAGE_RIGHT_WIDTH}
            height={HERO_DESKTOP_IMAGE_HEIGHT}
          />
        </div>
      </div>
    )
  }

  return (
    <div className='relative mt-0 mx-[-8px] mb-4'>
      <Container>
        <div className='w-full flex relative flex-col items-center justify-center py-5 px-3 gap-5 z-2 xl:py-8 xl:px-5'>
          <div className='text-center max-w-[750px]'>
            {renderTitle()}
            {renderSubtitle()}
          </div>
          {renderHeroBoxes()}
        </div>
      </Container>
      {renderBackground()}
    </div>
  )
}

HomePageHero.propTypes = {
  promoBlocks: PROMO_BLOCKS_PROP_TYPES,
  countryCode: PropTypes.string.isRequired,
  groupPosition: PropTypes.number.isRequired
}

HomePageHero.defaultProps = {
  promoBlocks: []
}

export default HomePageHero
