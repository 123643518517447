import { TPlaceholderProps } from './types'

const Placeholder = ({
  width = '100%',
  height = '',
  circular,
  rounded,
  className = ''
}: TPlaceholderProps): React.ReactElement => {
  return (
    <div
      role='presentation'
      aria-hidden='true'
      style={
        {
          '--placeholder-width': width,
          '--placeholder-height': height
        } as React.CSSProperties
      }
      className={`
        relative overflow-hidden bg-grey-100 animate-pulse-fast
        ${width && 'w-[var(--placeholder-width)]'}
        ${height && 'h-[var(--placeholder-height)]'}
        ${rounded && 'rounded-2'}
        ${circular && 'rounded-full'}
        ${className}
      `}
    />
  )
}

export default Placeholder
