import { getNextRoute } from '@helpers/routes'

export const getYear = () => new Date().getFullYear()

export const getTitle = (t) => {
  const year = getYear()
  return t('x_d_homepage_meta', {
    current_year: year,
    fallback: `Free Student Discounts | Valid ${year} Codes`
  })
}

export const getDescription = (t) =>
  t('x_m_homepage_meta', {
    fallback:
      'Looking for free student discounts? We work with brands to get you exclusive student deals. Gymshark, Pandora, WHSmith etc only at Student Beans!'
  })

export const getCanonical = (country) => {
  const route = getNextRoute('home', { country })
  return `https://www.studentbeans.com${route}`
}

export const getLdData = () => [{ type: 'Organisation' }]
