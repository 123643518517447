import PropTypes from 'prop-types'

import ContentRail from '@components/content_rail'
import MobileSwipeableRail from '@components/mobile_swipeable_rail'
import MobileSwipeableRailItem from '@components/mobile_swipeable_rail_item'
import OfferTile from '@components/offer_tile'

import { getImpressionContent } from './helpers'
import OfferRailPlaceholder from './placeholder'

const OfferRail = ({
  hasMarginBottom,
  impressionContent,
  loading,
  numberOfPlaceholders,
  offers,
  pathSuffix,
  subtitle,
  title,
  viewMoreHref
}) => {
  if (loading)
    return (
      <OfferRailPlaceholder
        hasMarginBottom={hasMarginBottom}
        hasSubtitle={!!subtitle}
        numberOfItems={numberOfPlaceholders}
      />
    )

  if (!offers?.length) return null

  const renderOfferItem = (offer, index) => {
    const isAdvert = offer.node?.isAdvert

    return (
      <MobileSwipeableRailItem key={offer.node.uid}>
        <OfferTile
          offer={offer.node}
          pathSuffix={pathSuffix}
          impressionContent={getImpressionContent(
            impressionContent,
            isAdvert,
            index
          )}
        />
      </MobileSwipeableRailItem>
    )
  }

  return (
    <ContentRail
      hasMarginBottom={hasMarginBottom}
      title={title}
      subtitle={subtitle}
      viewMoreHref={viewMoreHref}
    >
      <MobileSwipeableRail>{offers.map(renderOfferItem)}</MobileSwipeableRail>
    </ContentRail>
  )
}

OfferRail.propTypes = {
  hasMarginBottom: PropTypes.bool,
  impressionContent: PropTypes.shape({
    contentPosition: PropTypes.number,
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  }),
  loading: PropTypes.bool,
  numberOfPlaceholders: PropTypes.number,
  offers: PropTypes.array,
  pathSuffix: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired,
  viewMoreHref: PropTypes.string
}

OfferRail.defaultProps = {
  hasMarginBottom: false,
  loading: false,
  numberOfPlaceholders: 9,
  offers: [],
  subtitle: null,
  viewMoreHref: null,
  impressionContent: null
}

export default OfferRail
