import { shouldRenderHeroBoxes } from '@helpers/homepage'

import {
  HERO_BOXES_MULTI_TITLE_FALLBACK,
  HERO_BOXES_TITLE_FALLBACK,
  IMPRESSION_GROUP_ID,
  IMPRESSION_GROUP_TYPE
} from './constants'

export const getImpressionContent = (
  offer,
  groupPosition,
  contentPosition
) => ({
  groupPosition,
  groupId: IMPRESSION_GROUP_ID,
  groupType: IMPRESSION_GROUP_TYPE,
  contentPosition,
  contentType: offer.impressionContent?.type,
  contentId: offer.impressionContent?.id,
  contentVersionId: offer.impressionContent?.version
})

export const getHeroBoxesTitle = (offers, t) => {
  if (offers?.length === 1) {
    return t('m_hero_tile_ad_title_single', {
      fallback: HERO_BOXES_TITLE_FALLBACK
    })
  }

  return t('d_student_deals_of_the_day', {
    fallback: HERO_BOXES_MULTI_TITLE_FALLBACK
  })
}

export const shouldCentreTiles = (offers, countryCode) => {
  if (!shouldRenderHeroBoxes(countryCode)) return true

  if (!offers?.length) return true

  return false
}
