/* istanbul ignore file */
import { gql } from '@apollo/client'

export const PATH_SUFFIX = 'mediaboxes'

export const HERO_BOXES_TITLE_FALLBACK = 'Student deals of the day'

export const HERO_TILE_FRAGMENT = gql`
  fragment HeroTile on PromoItem {
    stylingConfig
    ... on EditorialPromo {
      title
      cta
      url
      stylingConfig
      status
      startDate
      endDate
    }
    ... on CampaignPromo {
      campaign {
        campaignSlug
        name
        countrySlug
        startDate
        endDate
        status
      }
    }
    ... on CollectionPromo {
      collection {
        slug
        name
        countrySlug
        startDate
        endDate
        status
      }
    }
  }
`

export const CAMPAIGN_CTA = 'View more'

export const COLLECTION_CTA = 'Discover deals'
