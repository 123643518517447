import PropTypes from 'prop-types'

import MobileSwipeableRail from '@components/mobile_swipeable_rail'
import MobileSwipeableRailItem from '@components/mobile_swipeable_rail_item'
import Placeholder from '@components/placeholder'

const MobileSwipeableRailPlaceholder = ({ numberOfItems }) => {
  const renderRailItemPlaceholder = () => (
    <div
      role='presentation'
      aria-hidden='true'
      data-testid='rail_item_placeholder'
    >
      <Placeholder
        width='100%'
        height='320px'
        rounded
      />
    </div>
  )

  return (
    <MobileSwipeableRail disableNav={true}>
      {Array.from(Array(numberOfItems)).map((_, index) => (
        <MobileSwipeableRailItem key={index}>
          {renderRailItemPlaceholder()}
        </MobileSwipeableRailItem>
      ))}
    </MobileSwipeableRail>
  )
}

MobileSwipeableRailPlaceholder.propTypes = {
  numberOfItems: PropTypes.number.isRequired
}

MobileSwipeableRailPlaceholder.defaultProps = {
  numberOfItems: 10
}

export default MobileSwipeableRailPlaceholder
