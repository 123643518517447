import { gql } from '@apollo/client'

import { OFFER_TILE_NEW_FRAGMENT } from '@components/offer_tile/constants'

export const MOST_POPULAR_RAIL_FRAGMENT = gql`
  fragment MostPopularRailOffers on OffersConnection {
    edges {
      node {
        uid
        ...OfferTileNewOffer
      }
    }
  }
  ${OFFER_TILE_NEW_FRAGMENT}
`

export const MOST_POPULAR_SUFFIX = '?source=most_popular_strip'
export const IMPRESSION_GROUP_TYPE = 'promotional_content'
export const IMPRESSION_GROUP_ID = 'trending_discounts'

export const MOST_POPULAR_RAIL_TITLE_FALLBACK = 'Most popular student discounts'
export const MOST_POPULAR_RAIL_SUBTITLE_FALLBACK =
  "Don't miss these trending student deals"
