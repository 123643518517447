import { hasIntersectionObserverSupport } from '@helpers/intersection_observer'

export const shouldObserve = (ref, hasBeenViewed, isSnowplowSetup) => {
  return !!(
    ref?.current &&
    !hasBeenViewed &&
    hasIntersectionObserverSupport() &&
    isSnowplowSetup
  )
}
