'use client'

import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useEffect, useRef, useState } from 'react'

import ShortArrow from '@thebeansgroup/ui/icons/short_arrow'

import {
  DESKTOP_ITEM_WIDTH_DEFAULT,
  MOBILE_ITEM_WIDTH_DEFAULT,
  NAV_DIRECTIONS
} from './constants'
import {
  setPartialScrollValue,
  hasFullScrollRemaining,
  hasLessThanFullScroll,
  scrollInDirection,
  shouldShowNav
} from './helpers'

const MobileSwipeableRail = ({
  children,
  disableNav,
  desktopItemWidth,
  mobileItemWidth,
  className
}) => {
  const railRef = useRef(null)

  useEffect(() => {
    setShowNav(shouldShowNav(disableNav, railRef?.current))
  }, [disableNav, railRef])

  const [prevNavActive, setPrevNavActive] = useState(false)
  const [nextNavActive, setNextNavActive] = useState(true)
  const [showNav, setShowNav] = useState(false)

  const setNavInactive = (direction) => {
    if (direction === NAV_DIRECTIONS.NEXT) {
      setNextNavActive(false)
      setPrevNavActive(true)
    }
    if (direction === NAV_DIRECTIONS.PREV) {
      setPrevNavActive(false)
      setNextNavActive(true)
    }
  }

  const setNavActive = () => {
    setPrevNavActive(true)
    setNextNavActive(true)
  }

  const handleNavClick = (direction) => {
    if (hasLessThanFullScroll(railRef?.current, direction)) {
      setPartialScrollValue(railRef?.current, direction)

      setNavInactive(direction)
      return
    }

    if (hasFullScrollRemaining(railRef?.current, direction)) {
      scrollInDirection(railRef?.current, direction, desktopItemWidth)
      setNavActive()
    }
  }

  const renderNav = () => {
    if (!showNav) return null

    return (
      <nav className='hidden lg:absolute lg:top-1/2 lg:translate-y-1/2 lg:w-full lg:flex lg:justify-between lg:z-3 lg:pointer-events-none'>
        <button
          data-testid='mobile-swipeable-rail-prev'
          className={clsx(
            'hidden group-hover:flex relative pointer-events-auto w-7 h-7 rounded-[50%] shadow-2 bg-violet-500 hover:bg-violet-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-liquorice-500 translate-x-[-50%]',
            !prevNavActive && '!bg-grey-100'
          )}
          onClick={() => handleNavClick(NAV_DIRECTIONS.PREV)}
        >
          <ShortArrow
            className={clsx(
              'w-5 h-5 -rotate-180 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] leading-[0]',
              prevNavActive ? 'stroke-white-300' : 'stroke-grey-200'
            )}
          />
        </button>
        <button
          data-testid='mobile-swipeable-rail-next'
          className={clsx(
            'hidden group-hover:flex relative pointer-events-auto w-7 h-7 rounded-[50%] shadow-2 bg-violet-500 hover:bg-violet-300 focus-visible:outline focus-visible:outline-2 focus-visible:outline-liquorice-500 translate-x-[50%]',
            !nextNavActive && '!bg-grey-100'
          )}
          onClick={() => handleNavClick(NAV_DIRECTIONS.NEXT)}
        >
          <ShortArrow
            className={clsx(
              'w-5 h-5 absolute top-1/2 left-1/2 translate-y-[-50%] translate-x-[-50%] leading-[0] overflow-auto',
              nextNavActive ? 'stroke-white-300' : 'stroke-grey-200'
            )}
          />
        </button>
      </nav>
    )
  }

  return (
    <div className={clsx('relative py-[10px] my-[-10px] group', className)}>
      <div
        ref={railRef}
        data-testid='mobile-swipeable-rail'
        style={{
          '--mobileItemWidthTailwindValue': `${mobileItemWidth}px`,
          '--desktopItemWidthTailwindValue': `${desktopItemWidth}px`
        }}
        className='auto-cols-[var(--mobileItemWidthTailwindValue)] lg:auto-cols-[var(--desktopItemWidthTailwindValue)] w-full grid grid-flow-col overflow-y-auto snap-x snap-mandatory scroll-smooth lg:py-[10px] lg:pl-[15px] lg:pr-3 lg:my-[-10px] lg:mx-[-15px] no-scrollbar::-webkit-scrollbar no-scrollbar focus-visible:outline focus-visible:outline-2 focus-visible:outline-liquorice-500 gap-3 lg:gap-4'
      >
        {children}
      </div>
      {renderNav()}
    </div>
  )
}

MobileSwipeableRail.propTypes = {
  children: PropTypes.node.isRequired,
  disableNav: PropTypes.bool,
  desktopItemWidth: PropTypes.number,
  mobileItemWidth: PropTypes.number,
  className: PropTypes.string
}

MobileSwipeableRail.defaultProps = {
  disableNav: false,
  desktopItemWidth: DESKTOP_ITEM_WIDTH_DEFAULT,
  mobileItemWidth: MOBILE_ITEM_WIDTH_DEFAULT,
  className: ''
}

export default MobileSwipeableRail
