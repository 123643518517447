import { SUFFIX_PARTIAL_SOURCE, SUFFIX_SOURCE_TYPE } from './constants'

export const getCollection = (collections, offset) => {
  return collections?.[offset]?.node ?? null
}

export const getOffers = (collection) => {
  return collection?.offers?.edges ?? null
}

export const hasCollectionOffers = (collection, offers) => {
  return !!(collection && offers)
}

export const getImpressionContent = (groupPosition, impressionContent) => ({
  groupType: impressionContent.type,
  groupId: impressionContent.id,
  groupPosition,
  groupVersionId: impressionContent.version
})

export const getCuratedCollectionSuffix = (collection) => {
  const collectionSlugWithUnderScores = collection.slug.replace(/-/g, '_')
  return (
    SUFFIX_PARTIAL_SOURCE + collectionSlugWithUnderScores + SUFFIX_SOURCE_TYPE
  )
}
