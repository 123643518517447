import PropTypes from 'prop-types'

import OfferRail from '@components/offer_rail'
import { getNextRoute } from '@helpers/routes'

import {
  getCollection,
  getCuratedCollectionSuffix,
  getImpressionContent,
  getOffers,
  hasCollectionOffers
} from './helpers'

const CuratedCollectionRail = ({
  curatedCollections,
  curatedCollectionOffset,
  groupPosition
}) => {
  const collection = getCollection(curatedCollections, curatedCollectionOffset)
  const offers = getOffers(collection)

  if (!hasCollectionOffers(collection, offers)) return null

  return (
    <div data-testid={`curated-collection-${curatedCollectionOffset}-rail`}>
      <OfferRail
        hasMarginBottom
        impressionContent={getImpressionContent(
          groupPosition,
          collection.impressionContent
        )}
        offers={offers}
        pathSuffix={getCuratedCollectionSuffix(collection)}
        subtitle={collection.description}
        title={collection.name}
        viewMoreHref={getNextRoute('collection.show', {
          country: collection.countrySlug.toLowerCase(),
          collection: collection.slug
        })}
      />
    </div>
  )
}

CuratedCollectionRail.propTypes = {
  curatedCollections: PropTypes.array.isRequired,
  curatedCollectionOffset: PropTypes.number.isRequired,
  groupPosition: PropTypes.number.isRequired
}

export default CuratedCollectionRail
