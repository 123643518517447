import { gql } from '@apollo/client'
import PropTypes from 'prop-types'

import client from '@root/apollo_clients/web/ssr'

import { CURATED_COLLECTION_RAIL_COLLECTIONS_FRAGMENT } from '@components/curated_collection_rail/constants'
import HomePage from '@components/home_page'
import { HOME_PAGE_HERO_FRAGMENT } from '@components/home_page_hero/constants'
import { MOST_POPULAR_RAIL_FRAGMENT } from '@components/most_popular_rail/constants'
import { NEW_TODAY_RAIL_FRAGMENT } from '@components/new_today_rail/constants'
import { PAGE_VIEWER_PROMO_BANNER_FRAGMENT } from '@components/page/constants'
import { setCloudFrontCacheToExpireOnTheHour } from '@helpers/cache'
import { getCountryOptions } from '@helpers/countries'
import { handleServerSideError } from '@helpers/errors'
import { getTranslationsByCountry } from '@helpers/translations'
import { useCountryCode } from '@hooks/use_country_code'

const Home = ({
  promoBlocks,
  popularOffers,
  newTodayOffers,
  curatedCollections,
  heroPromoBlocks
}) => {
  const countryCode = useCountryCode()

  return (
    <HomePage
      promoBlocks={promoBlocks}
      popularOffers={popularOffers}
      newTodayOffers={newTodayOffers}
      curatedCollections={curatedCollections}
      heroPromoBlocks={heroPromoBlocks}
      countryCode={countryCode}
    />
  )
}

export const GET_HOME_QUERY = gql`
  query WebHomeQuery($countrySlugs: [String!], $countryCode: String!) {
    accountsViewer {
      ...PageViewerPromoBanner
      id
      popularOffers: trendingOffers(countryCode: $countryCode) {
        offers(first: 20) {
          ...MostPopularRailOffers
        }
      }
      newTodayOffers: newestOffers(countryCode: $countryCode) {
        offers(first: 20) {
          ...NewTodayRailOffers
        }
      }
      curatedCollections: discoveryCollections(
        countryCodes: $countrySlugs
        v2Offers: true
        limit: 3
        featured: true
      ) {
        ...CuratedCollectionRailCollections
      }
      heroPromoBlocks: promoBlocks(
        countrySlug: $countryCode
        platform: WEB
        useKevel: false
      ) {
        edges {
          node {
            ...HomePageHeroFragment
          }
        }
      }
    }
  }
  ${PAGE_VIEWER_PROMO_BANNER_FRAGMENT}
  ${MOST_POPULAR_RAIL_FRAGMENT}
  ${NEW_TODAY_RAIL_FRAGMENT}
  ${CURATED_COLLECTION_RAIL_COLLECTIONS_FRAGMENT}
  ${HOME_PAGE_HERO_FRAGMENT}
`

export async function getServerSideProps(context) {
  const countryCode = context?.params?.country

  const { fullSupport } = getCountryOptions(countryCode)
  if (!fullSupport) {
    return {
      notFound: true
    }
  }

  const translations = getTranslationsByCountry(countryCode)

  try {
    const { data } = await client.query({
      query: GET_HOME_QUERY,
      variables: {
        countrySlugs: [countryCode],
        countryCode
      }
    })

    setCloudFrontCacheToExpireOnTheHour(context)

    return {
      props: {
        promoBlocks: data?.accountsViewer?.promoBlocks?.edges ?? null,
        translations,
        popularOffers: data?.accountsViewer?.popularOffers?.offers ?? null,
        newTodayOffers: data?.accountsViewer?.newTodayOffers?.offers ?? null,
        curatedCollections:
          data?.accountsViewer?.curatedCollections?.edges ?? [],
        heroPromoBlocks: data?.accountsViewer?.heroPromoBlocks?.edges ?? []
      }
    }
  } catch (error) {
    return handleServerSideError(error, context, translations)
  }
}

Home.propTypes = {
  promoBlocks: PropTypes.array,
  translations: PropTypes.object.isRequired,
  popularOffers: PropTypes.object,
  newTodayOffers: PropTypes.object,
  curatedCollections: PropTypes.array,
  heroPromoBlocks: PropTypes.array
}

Home.defaultProps = {
  promoBlocks: null,
  trendingOffers: null,
  popularOffers: null,
  newTodayOffers: null,
  curatedCollections: [],
  heroPromoBlocks: []
}

export default Home
