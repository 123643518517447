import ContentRailPlaceholder from '@components/content_rail/placeholder'
import OfferTileMiniPlaceholder from '@components/offer_tile_mini/placeholder'

const HeroBoxesPlaceholder = () => {
  const renderPlaceholders = () => {
    return (
      <div className='overflow-y-auto overflow-x-hidden snap-x snap-mandatory scroll-smooth grid-flow-col lg:overflow-auto'>
        <div className='grid grid-rows-3 grid-cols-2 gap-y-3 gap-x-[260px] md:gap-x-[110px] justify-start overflow-x-auto lg:gap-y-2 lg:gap-x-5 xl:overflow-auto no-scrollbar::-webkit-scrollbar no-scrollbar'>
          <OfferTileMiniPlaceholder />
          <OfferTileMiniPlaceholder />
          <OfferTileMiniPlaceholder />
          <OfferTileMiniPlaceholder />
          <OfferTileMiniPlaceholder />
          <OfferTileMiniPlaceholder />
        </div>
      </div>
    )
  }

  return (
    <>
      <div className='shadow-2 rounded-2 bg-white-300 z-6 overflow-hidden w-full p-4 xl:w-auto hidden xl:block'>
        <ContentRailPlaceholder>{renderPlaceholders()}</ContentRailPlaceholder>
      </div>
      <div className='shadow-2 rounded-2 bg-white-300 z-6 overflow-hidden w-full p-4 xl:w-auto xl:hidden'>
        <ContentRailPlaceholder hasSubtitle>
          {renderPlaceholders()}
        </ContentRailPlaceholder>
      </div>
    </>
  )
}

export default HeroBoxesPlaceholder
