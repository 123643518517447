import { gql } from '@apollo/client'

import {
  HERO_BOXES_FRAGMENT,
  KEVEL_HERO_BOXES_FRAGMENT
} from '@components/hero_boxes/constants'

export const HERO_TITLE_FALLBACK = 'Student deals of the day'

export const HERO_SUBTITLE_FALLBACK =
  'Your new favourite, student discount website with deals you won’t find anywhere else.'

export const HERO_LEFT_IMAGE_PATH = 'images/home_page_hero/home_hero_left.png'

export const HERO_RIGHT_IMAGE_PATH = 'images/home_page_hero/home_hero_right.png'

export const HERO_DESKTOP_IMAGE_LEFT_WIDTH = 150

export const HERO_DESKTOP_IMAGE_RIGHT_WIDTH = 180

export const HERO_DESKTOP_IMAGE_HEIGHT = 270

export const HOMEPAGE_HERO_TILE_TYPE = 'HOMEPAGE_HERO_TILE'

export const HOME_PAGE_HERO_FRAGMENT = gql`
  fragment HomePageHeroFragment on PromoBlock {
    promoBlockType
    promoItems {
      edges {
        node {
          ...HeroBoxesPromoBlocks
        }
      }
    }
  }
  ${HERO_BOXES_FRAGMENT}
`

export const HOME_PAGE_HERO_QUERY = gql`
  query HomePageHeroQuery($countryCode: String!) {
    accountsViewer {
      id
      kevelPromoBoxes: kevelAdverts(
        countrySlug: $countryCode
        platform: WEB
        placementType: PROMOBOX
        count: 6
      ) {
        edges {
          node {
            ...KevelHeroBoxes
          }
        }
      }
    }
  }
  ${KEVEL_HERO_BOXES_FRAGMENT}
`
