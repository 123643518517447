import * as Sentry from '@sentry/browser'

import {
  IMPRESSION_CLICK_SCHEMA_URI,
  IMPRESSION_EVENT_NAME,
  IMPRESSION_LOAD_STORE_NAME,
  IMPRESSION_VIEW_STORE_NAME
} from '@constants/impression_tracking'
import { hasIntersectionObserverSupport } from '@helpers/intersection_observer'
import { trackKevelClickImpression } from '@helpers/kevel'
import { trackUnstructEvent } from '@tracker/snowplow'

import { captureExceptionError } from './capture_exception_error'

export const trackImpressionClick = (impressionId, kevelData) => {
  if (impressionId) {
    trackUnstructEvent(IMPRESSION_CLICK_SCHEMA_URI, {
      impression_id: impressionId
    })
  }

  trackKevelClickImpression(kevelData)

  return null
}

export const sendEvent = (data) => {
  document.dispatchEvent(
    new window.CustomEvent(IMPRESSION_EVENT_NAME, { detail: data })
  )
}

export const sendImpressionLoadEvent = (data) => {
  sendEvent({ [IMPRESSION_LOAD_STORE_NAME]: data })
}

export const sendImpressionViewEvent = (impressionId) => {
  sendEvent({ [IMPRESSION_VIEW_STORE_NAME]: { impression_id: impressionId } })
}

export const getImpressionRef = (ref, isEnabled) => {
  if (!isEnabled) return null

  return ref
}

export const reportErrorHandler = (error, handler) => {
  Sentry.withScope(function (scope) {
    scope.setTag('sb_handler', handler)
    captureExceptionError(error)
  })
}

export const storeEvent = async (storeName, value) => {
  try {
    sendEvent({ [storeName]: value })
  } catch (error) {
    reportErrorHandler(error, 'useImpressionTracking')
  }
}

export const storeImpressionLoad = async (data, impressionId) => {
  storeEvent(IMPRESSION_LOAD_STORE_NAME, {
    ...data,
    impression_id: impressionId
  })
}

export const storeImpressionView = (impressionId, kevelImpressionData) => {
  storeEvent(IMPRESSION_VIEW_STORE_NAME, {
    impression_id: impressionId,
    kevelImpressionData
  })
}

export const isEnabled = (data, isSnowplowSetup) =>
  !!(data && hasIntersectionObserverSupport() && isSnowplowSetup)

export const getValueOrNull = (value) => (value != null ? value : null)

export const allObjValuesAreDefined = (obj) =>
  Object.values(obj).every((val) => val != null)
