import { DESKTOP_ITEM_GAP, NAV_DIRECTIONS } from './constants'

export const getMaxScroll = (railContainer) => {
  return railContainer?.scrollWidth - railContainer?.clientWidth
}

export const getRemainingWidth = (railContainer, direction) => {
  if (direction === NAV_DIRECTIONS.NEXT) {
    return getMaxScroll(railContainer) - railContainer?.scrollLeft
  }

  return railContainer?.scrollLeft
}

export const hasLessThanFullScroll = (railContainer, direction) => {
  const remainingWidth = getRemainingWidth(railContainer, direction)

  return remainingWidth < railContainer?.offsetWidth
}

export const setPartialScrollValue = (railContainer, direction) => {
  if (direction === NAV_DIRECTIONS.NEXT) {
    railContainer.scrollLeft = getMaxScroll(railContainer)
    return
  }

  railContainer.scrollLeft = 0
}

export const hasFullScrollRemaining = (railContainer, direction) => {
  const remainingWidth = getRemainingWidth(railContainer, direction)
  return remainingWidth >= railContainer?.offsetWidth
}

const getVisibleItems = (railContainer, desktopItemWidth) =>
  Math.floor(railContainer.offsetWidth / desktopItemWidth)

const getScrollDistance = (items, desktopItemWidth) => {
  return items * desktopItemWidth + items * DESKTOP_ITEM_GAP
}

export const scrollInDirection = (
  railContainer,
  direction,
  desktopItemWidth
) => {
  const items = getVisibleItems(railContainer, desktopItemWidth)

  if (direction === NAV_DIRECTIONS.NEXT) {
    railContainer.scrollLeft += getScrollDistance(items, desktopItemWidth)
    return
  }

  railContainer.scrollLeft -= getScrollDistance(items, desktopItemWidth)
}

export const hasEnoughContentToScroll = (railContainer) => {
  return railContainer?.scrollWidth > railContainer?.offsetWidth
}

export const shouldShowNav = (disableNav, railContainer) => {
  return !disableNav && hasEnoughContentToScroll(railContainer)
}
