import { gql } from '@apollo/client'

import { HERO_TILE_FRAGMENT } from '@components/hero_tile/constants'
import { KEVEL_TRACKING_FRAGMENT } from '@constants/kevel'

export const PATH_SUFFIX = '?source=promoboxes'
export const IMPRESSION_GROUP_TYPE = 'promotional_content'
export const IMPRESSION_GROUP_ID = 'promoboxes'
export const HOMEPAGE_HERO_TILE = 'HOMEPAGE_HERO_TILE'

export const HERO_BOXES_TITLE_FALLBACK = 'Student deal of the day'
export const HERO_BOXES_MULTI_TITLE_FALLBACK = 'Student deals of the day'

export const KEVEL_HERO_BOXES_FRAGMENT = gql`
  fragment KevelHeroBoxes on KevelAdvert {
    id
    ... on KevelOffer {
      ...KevelTrackingViewer
      offer {
        uid
        title
        slug
        brand {
          slug
          name
          logo
        }
        country {
          slug
        }
        impressionContent {
          id
          type
          version
        }
      }
    }
  }
  ${KEVEL_TRACKING_FRAGMENT}
`

export const HERO_BOXES_FRAGMENT = gql`
  fragment HeroBoxesPromoBlocks on PromoItem {
    ...HeroTile
  }
  ${HERO_TILE_FRAGMENT}
`
