import PropTypes from 'prop-types'

import ContentRail from '@components/content_rail'
import MobileSwipeableRail from '@components/mobile_swipeable_rail'
import MobileSwipeableRailItem from '@components/mobile_swipeable_rail_item'
import OfferTile from '@components/offer_tile'
import { getNextRoute } from '@helpers/routes'
import { useCountryCode } from '@hooks/use_country_code'
import { useI18n } from '@hooks/use_i18n'

import {
  MOST_POPULAR_RAIL_SUBTITLE_FALLBACK,
  MOST_POPULAR_RAIL_TITLE_FALLBACK,
  MOST_POPULAR_SUFFIX
} from './constants'
import { getImpressionContent, shouldRenderMostPopularRail } from './helpers'

const MostPopularRail = ({ groupPosition, offers }) => {
  const country = useCountryCode()
  const { t } = useI18n()

  if (!shouldRenderMostPopularRail(offers)) return null

  const renderRailItem = (offer, index) => {
    const isAdvert = offer.node?.isAdvert

    return (
      <MobileSwipeableRailItem key={offer.node.uid}>
        <OfferTile
          offer={offer.node}
          pathSuffix={MOST_POPULAR_SUFFIX}
          impressionContent={{
            ...getImpressionContent(groupPosition, isAdvert),
            contentPosition: index
          }}
        />
      </MobileSwipeableRailItem>
    )
  }

  return (
    <ContentRail
      hasMarginBottom
      title={t('d_most_popular_title', {
        fallback: MOST_POPULAR_RAIL_TITLE_FALLBACK
      })}
      subtitle={t('m_most_popular_subtitle', {
        fallback: MOST_POPULAR_RAIL_SUBTITLE_FALLBACK
      })}
      viewMoreHref={getNextRoute('trending', { country })}
    >
      <MobileSwipeableRail>
        {offers?.edges.map(renderRailItem)}
      </MobileSwipeableRail>
    </ContentRail>
  )
}

MostPopularRail.propTypes = {
  groupPosition: PropTypes.number.isRequired,
  offers: PropTypes.object
}

MostPopularRail.defaultProps = {
  offers: {}
}

export default MostPopularRail
