export const REDEMPTION_CLASSES = {
  ONLINE: 'Online',
  INSTORE: 'Instore'
}

export const REDEMPTION_TYPES = {
  GENERIC_MULTI_USE_CODE: 'generic_multi_use_code',
  INDIVIDUAL_USER_CODE: 'individual_user_code',
  NO_CODE: 'no_code',
  SBID: 'sbid_number',
  UNIQUE_SINGLE_USE_CODE: 'unique_single_use_code'
}

export const REDEMPTION_TYPES_PROP_TYPES = [
  REDEMPTION_TYPES.GENERIC_MULTI_USE_CODE,
  REDEMPTION_TYPES.INDIVIDUAL_USER_CODE,
  REDEMPTION_TYPES.NO_CODE,
  REDEMPTION_TYPES.SBID,
  REDEMPTION_TYPES.UNIQUE_SINGLE_USE_CODE
]

export const REDEMPTION_STEP_ADDITIONAL_CONTENT = 'additional_content_education'

export const OFFER_CONTENT_TYPES = {
  NATIVE_STUDENT_DISCOUNT: 'NATIVE_STUDENT_DISCOUNT',
  COMPETITOR_STUDENT_DISCOUNT: 'COMPETITOR_STUDENT_DISCOUNT',
  VOUCHER: 'VOUCHER',
  FREEBIE: 'FREEBIE',
  SALE: 'SALE',
  COMPETITION: 'COMPETITION'
}

export const OFFER_CONTENT_TYPES_LOWER = {
  NATIVE_STUDENT_DISCOUNT: 'native_student_discount',
  COMPETITOR_STUDENT_DISCOUNT: 'competitor_student_discount',
  VOUCHER: 'voucher',
  FREEBIE: 'freebie',
  SALE: 'sale',
  COMPETITION: 'competition'
}
