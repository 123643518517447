import { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  storeImpressionLoad,
  storeImpressionView,
  isEnabled
} from '@helpers/impression_tracking'
import useImpressionView from '@hooks/use_impression_view'
import { useIsSnowplowSetup } from '@hooks/use_is_snowplow_setup'

const useImpressionTracking = (data, ref, handleError, kevelImpressionData) => {
  const { isSnowplowSetup } = useIsSnowplowSetup()
  const [impressionId] = useState(() => uuidv4())

  useEffect(() => {
    if (isEnabled(data, isSnowplowSetup))
      storeImpressionLoad(data, impressionId)
  }, [isSnowplowSetup]) // eslint-disable-line react-hooks/exhaustive-deps

  useImpressionView(
    ref,
    storeImpressionView,
    impressionId,
    isSnowplowSetup,
    kevelImpressionData
  )

  if (isEnabled(data, isSnowplowSetup)) return { impressionId }

  return { impressionId: null }
}

export default useImpressionTracking
