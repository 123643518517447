import PropTypes from 'prop-types'

import ComponentErrorBoundary from '@components/component_error_boundary'
import OfferTileBrand from '@components/offer_tile_brand'
import OfferTileLinkContainer from '@components/offer_tile_link_container'
import OfferTileLogo from '@components/offer_tile_logo'
import OfferTileTitle from '@components/offer_tile_title'
import { addSentryTag } from '@helpers/offer_tile'

const OfferTileMini = ({
  offer,
  pathSuffix,
  impressionContent,
  onClick,
  className
}) => {
  return (
    <ComponentErrorBoundary
      addSentryTag={(scope) => addSentryTag(scope, offer)}
    >
      <OfferTileLinkContainer
        offer={offer}
        pathSuffix={pathSuffix}
        impressionContent={impressionContent}
        onClick={onClick}
        className={className}
      >
        <div className='flex items-center gap-[10px] h-full'>
          <OfferTileLogo
            offer={offer}
            width={48}
            height={48}
            className='border-[1px] border-solid border-grey-100 rounded-3 p-2 leading-[0] xl:hidden'
          />
          <OfferTileLogo
            offer={offer}
            width={56}
            height={56}
            className='hidden border-[1px] border-solid border-grey-100 rounded-3 p-2 leading-[0] xl:block'
          />
          <div className='w-max p-2'>
            <OfferTileTitle
              offer={offer}
              mini
            />
            <OfferTileBrand
              offer={offer}
              mini
            />
          </div>
        </div>
      </OfferTileLinkContainer>
    </ComponentErrorBoundary>
  )
}

OfferTileMini.propTypes = {
  offer: PropTypes.object.isRequired,
  pathSuffix: PropTypes.string.isRequired,
  impressionContent: PropTypes.shape({
    contentPosition: PropTypes.number,
    groupType: PropTypes.string,
    groupId: PropTypes.string,
    groupPosition: PropTypes.number
  }),
  onClick: PropTypes.func,
  className: PropTypes.string
}

OfferTileMini.defaultProps = {
  impressionContent: null,
  onClick: null,
  className: ''
}

export default OfferTileMini
